@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  /* Faux outline for older browsers */
  .outline-title {
    color: white; /* Unfortunately you can't use transparent here … */
    text-shadow:
      -1px -1px 0 #f1a086,
      1px -1px 0 #f1a086,
      -1px 1px 0 #f1a086,
      1px 1px 0 #f1a086;
  }

  /* Real outline for modern browsers */
  @supports ((text-stroke: 2px #f1a086) or (-webkit-text-stroke: 2px #f1a086)) {
    .outline-title {
      color: transparent;
      -webkit-text-stroke: 2px #f1a086;
      text-stroke: 2px #f1a086;
      text-shadow: none;
    }
  }

  .heading-sm {
    @apply text-xl font-medium text-primary;
  }

  .heading-md {
    @apply text-2xl font-medium text-primary md:text-3xl;
  }

  .heading-lg {
    @apply text-3xl font-medium text-primary;
  }
}

@import "./_swiper";

$primary: #ff5425;

body {
  top: 0 !important;
}

#main-content {
  min-height: 50vh;
}

div.skiptranslate,
#google_translate_element2,
.VIpgJd-ZVi9od-aZ2wEe-wOHMyf-ti6hGc {
  display: none !important;
}

.range-slider {
  .thumb {
    top: -7px;
    border: 2px solid $primary;
    border-radius: 100%;
    background-color: #fff;
    width: 20px;
    height: 20px;
    overflow: hidden;
    text-indent: -9999px;
  }

  .track {
    border-radius: 7px;
    background-color: #e5e7eb;
    height: 4px;

    &.track-1 {
      background-color: $primary;
    }
  }
}
