$primary-color: #ff5425;

.gallery-swiper .swiper-slide {
  opacity: 0.4;
  cursor: pointer;
  width: 25%;
  height: 100%;
}

.gallery-swiper .swiper-slide:hover {
  opacity: 1;
}

.gallery-swiper .swiper-slide-thumb-active {
  opacity: 1;
}

.swiper-button-next:after,
.swiper-button-prev:after {
  font-size: 1rem !important;
}

.swiper-button-next,
.swiper-button-prev {
  border-radius: 100%;
  background-color: hsla(0, 0%, 100%, 0.8);
  width: 2.5rem !important;
  height: 2.5rem !important;
  color: #111111 !important;
}

.swiper-button-next:hover,
.swiper-button-prev:hover {
  background-color: #ffffff;
}

.swiper-pagination-bullet-active {
  background-color: $primary-color !important;
}

.swiper-slide {
  height: auto;
}
