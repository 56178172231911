// WordPress block styles.

@use "sass:math";

@import "@wordpress/base-styles/mixins";
@import "@wordpress/base-styles/colors.native";
@import "@wordpress/base-styles/z-index";
@import "@wordpress/base-styles/default-custom-properties";
@import "@wordpress/base-styles/colors";
@import "@wordpress/base-styles/variables";
@import "@wordpress/base-styles/breakpoints";
@import "@wordpress/block-library/src/style";
@import "@wordpress/block-library/src/theme";

.has-large-font-size {
  font-weight: 500;
  font-size: 1.5em !important;
  line-height: 1.5;
}

@media screen and (min-width: 782px) {
  .has-large-font-size {
    font-size: var(--wp--preset--font-size--large) !important;
  }
}
